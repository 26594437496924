import { withChildren } from "@builder.io/react";
import React, { Fragment, useState } from "react";

const AccordionV1 = ({ children }) => {
  const [opened, setOpened] = useState(null);
  if (!children) return null;

  const onClickHandler = currentIndex => {
    setOpened(opened === currentIndex ? null : currentIndex);
  };

  return children.map((item, index) =>
    index % 2 === 0 ? (
      <Fragment key={index}>
        <div
          className={`accordion ${opened === index ? "opened" : ""}`}
          onClick={() => onClickHandler(index)}
        >
          {children[index]}
        </div>
        <div style={{ display: opened === index ? "block" : "none" }}>
          {children[index + 1]}
        </div>
      </Fragment>
    ) : null
  );
};
export default withChildren(AccordionV1);
